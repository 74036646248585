<template>
  <div class="canvas-write" :class="mode">
    <div class="operator-box">
      <div class="input-box">
        <label for="pencil-color">颜色：</label>
        <input
          type="color"
          v-model="color"
          class="pencil-color"
          id="pencil-color"
        />
        <label for="pencil-size">画笔粗细：</label>
        <input
          type="number"
          v-model="size"
          class="pencil-size"
          id="pencil-size"
        />
      </div>
      <button @click="clearCanvas">清除</button>
      <button @click="complete">完成</button>
    </div>
    <div class="canvas-box">
      <img v-if="filePath" :src="filePath" alt="" />
      <canvas
        v-else
        :width="canvasWidth"
        :height="canvasHeight"
        ref="canvasBox"
      ></canvas>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      color: 'black',
      size: 1,
      filePath: this.file,
      canvasWidth: 350,
      canvasHeight: 500,
      mode: 'vertical'
    }
  },
  watch: {
    file() {
      this.filePath = this.file
    }
  },
  methods: {
    /**
     * 初始化canvas  获取canvas画板 设置画板的宽高
     */
    initCanvas() {
      if (this.filePath) {
        return false
      }
      var canvasBox = this.$refs.canvasBox
      this.canvasWidth = canvasBox.offsetWidth
      this.canvasHeight = canvasBox.offsetHeight
      this.ctx = canvasBox.getContext('2d')
      canvasBox.addEventListener('mouseenter', () => {
        // 监听鼠标指针按下
        canvasBox.addEventListener('mousedown', (e) => {
          // 开始绘制路径
          this.ctx.beginPath()
          this.ctx.strokeStyle = this.color
          this.ctx.lineWidth = this.size
          // 设置绘制的起点为当前点击的位置
          this.ctx.moveTo(e.offsetX, e.offsetY)
          // 监听键盘移动事件
          canvasBox.addEventListener('mousemove', this.draw)
        })
        canvasBox.addEventListener('mouseup', () => {
          // 移除鼠标移动事件
          canvasBox.removeEventListener('mousemove', this.draw)
        })
      })
    },
    // 绘制
    draw(e) {
      // var canvasBox = this.$refs.canvasBox
      // const x = e.clientX - canvasBox.offsetLeft
      // const y = e.clientY - canvasBox.offsetTop
      const x = e.offsetX
      const y = e.offsetY
      this.ctx.lineTo(x, y)
      this.ctx.stroke()
    },
    // 清空canvas
    clearCanvas() {
      this.filePath = ''
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      }
      // 清空之后重新初始化canvas
      setTimeout(() => {
        this.initCanvas()
      }, 10)
    },
    // 点击完成
    complete() {
      this.$nextTick(() => {
        this.filePath = this.$refs.canvasBox.toDataURL()
        this.$emit('onComplete', this.filePath)
      })
    }
  },
  mounted() {
    this.initCanvas()
  }
}
</script>

<style lang='less' scoped>
* {
  margin: 0;
}
canvas {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.clear {
  width: 600px;
  height: 50px;
  border-radius: 10px;
  background-color: #e4e7ed;
  margin-top: 10px;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.clear:hover {
  background-color: #c0c4cc;
}

.canvas-write {
  font-size: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  .operator-box {
    .input-box {
      width: 230px;
      display: inline-block;
      input {
        display: inline;
        line-height: 20px;
        border-radius: 5px;
        border: 1px solid #dcdfe6;
        padding: 0 5px;
        margin: 0 5px;
        max-width: 60px;
        &.pencil-size {
          width: 78px;
        }
      }
    }
    button {
      width: 40px;
      height: 20px;
      padding: 0;
      border-radius: 3px;
      color: #666;
      margin-left: 5px;
      border: 1px solid #ececec;
      outline: none;
      background: #fff;
      &:active {
        background: #ececec;
      }
    }
  }
  .canvas-box {
    width: calc(100%-20px);
    height: 500px;
    border: 1px solid #fbd3de;
    border-radius: 10px;
    margin: 10px;
    box-sizing: border-box;
    img,
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
.horizontal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
  overflow: hidden;
  .canvas-box {
    width: calc(100% - 100px);
    height: 90vh;
  }
  .operator-box {
    width: 25px;
    height: 90vh;
    flex-direction: column;
    padding-right: 35px;
    button {
      transform: rotate(90deg);
      margin: 40px 0px 10px;
    }
    .input-box {
      transform-origin: 0 30px;
      transform: rotate(90deg);
      margin: 0px 0px 227px 0px;
    }
  }
}
</style>
