import request from '@/utils/request'

const api = {
  myCorp: 'frm/corp/my'
}

function myCorpOrJoin(params) {
  return new Promise((resolve, reject) => {
    request.get(api.myCorp, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function getDataByParams(params) {
  if (params.dataSource === 'myCorpOrJoin') {
    return myCorpOrJoin(params)
  }
}
